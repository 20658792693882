import React, { useState } from "react"
import { Container, Row, Col, Image, Button } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import { useMediaQuery } from "react-responsive"

import Header from "../../components/Header"
import JobCard from "../../components/JobCard"

import Footer from "../../components/Footer"

import { useGlobalState } from "../../global/global"
import { getLanguageFile } from "../../global/langauge.helper"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import CUBES from "../../../assets/images/careerDetailCubes.png"

import "./styles.scss"

const CareerPage = () => {
  const [state] = useGlobalState()
  const [isViewAll, setIsViewAll] = useState(false)
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  })
  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const onViewAll = () => {
    setIsViewAll(!isViewAll)
  }

  return (
    <Container fluid className="main-container">
      <div className="application">
        <Helmet title={getLanguageFile(state.language).Title.career}>
          <meta charSet="utf-8" />
        </Helmet>
      </div>
      <Header headerTextMode="dark" />
      <Container fluid className="career-image-container">
        <Image className={"career-image-background"} src={CUBES} />
      </Container>
      <Container
        fluid
        className="career-first-section d-flex align-items-center"
      >
        <Container className="career-introduction-container">
          <Row>
            <Col className={"career-introduction-texts-col col-lg-8 col-10"}>
              <Row>
                <h2 className="career-introduction-title">
                  {getLanguageFile(state.language).Career.introductionTitle}
                </h2>
              </Row>
              <Row>
                <p className="career-introduction-description">
                  {
                    getLanguageFile(state.language).Career
                      .introductionDescriptionOne
                  }
                </p>
                <p className="career-introduction-description">
                  {
                    getLanguageFile(state.language).Career
                      .introductionDescriptionTwo
                  }
                </p>
                <p className="career-introduction-description">
                  {
                    getLanguageFile(state.language).Career
                      .introductionDescriptionThree
                  }
                </p>
                <p className="career-introduction-description">
                  {
                    getLanguageFile(state.language).Career
                      .introductionDescriptionFour
                  }
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="second-title-container">
        <h2 className="second-title">
          {getLanguageFile(state.language).Career.secondTitle}
        </h2>
      </Container>
      <Container className="job-card-row-container">
        {isViewAll ? (
          <>
            <Row>
              <JobCard
                extraStyle={{
                  width: 280,
                }}
                className="job-card"
                cardName="gameDeveloper_001"
                cardTitle={
                  getLanguageFile(state.language).Career.gameApplication
                }
                cardDescription={
                  getLanguageFile(state.language).Career
                    .gameApplicationDescription
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
              <JobCard
                extraStyle={{
                  width: 280,
                }}
                className="job-card"
                cardName="electricalandElectronicsEngineer"
                cardTitle={getLanguageFile(state.language).Career.eeApplication}
                cardDescription={
                  getLanguageFile(state.language).Career
                    .eeApplicationDescription
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
              <JobCard
                extraStyle={{
                  width: 280,
                }}
                className="job-card"
                cardName="softwareEngineer_006"
                cardTitle={
                  getLanguageFile(state.language).Career.bdiApplication
                }
                cardDescription={
                  getLanguageFile(state.language).Career
                    .bdiApplicationDescription
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
            </Row>
            <Row>
              <JobCard
                extraStyle={{
                  width: 280,
                }}
                className="job-card"
                cardName="sysadmin_001"
                cardTitle={getLanguageFile(state.language).Career.saApplication}
                cardDescription={
                  getLanguageFile(state.language).Career
                    .saApplicationDescription
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
              <JobCard
                extraStyle={{
                  width: 280,
                }}
                className="job-card"
                cardName="softwareEngineer_005"
                cardTitle={
                  getLanguageFile(state.language).Career.hmiApplication2
                }
                cardDescription={
                  getLanguageFile(state.language).Career
                    .hmiApplicationDescription2
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
              <JobCard
                extraStyle={{
                  width: 280,
                }}
                className="job-card"
                cardName="softwareEngineer_004"
                cardTitle={
                  getLanguageFile(state.language).Career.nlpApplication
                }
                cardDescription={
                  getLanguageFile(state.language).Career
                    .nlpApplicationDescription
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
            </Row>
            <Row>
              <JobCard
                extraStyle={{
                  width: 280,
                }}
                className="job-card"
                cardName="softwareEngineer_003"
                cardTitle={
                  getLanguageFile(state.language).Career.hmiApplication
                }
                cardDescription={
                  getLanguageFile(state.language).Career
                    .hmiApplicationDescription
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
              <JobCard
                extraStyle={{
                  width: 280,
                }}
                className="job-card"
                cardName="softwareEngineer_002"
                cardTitle={getLanguageFile(state.language).Career.msApplication}
                cardDescription={
                  getLanguageFile(state.language).Career
                    .msApplicationDescription
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
              <JobCard
                extraStyle={{
                  width: 280,
                }}
                className="job-card"
                cardName="softwareEngineer_001"
                cardTitle={
                  getLanguageFile(state.language).Career.softwareEngineer
                }
                cardDescription={
                  getLanguageFile(state.language).Career
                    .softwareEngineerDescription
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
            </Row>
            <Row className="last-card-row">
              <JobCard
                extraStyle={{
                  width: 280,
                }}
                className="job-card"
                cardName="intern"
                cardTitle={
                  getLanguageFile(state.language).Career.internJobDescription
                }
                cardDescription={
                  getLanguageFile(state.language).Career.internDescription
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
              <JobCard
                extraStyle={{
                  width: 280,
                  alignSelf: "flex-start",
                }}
                className="job-card"
                cardName="partTime"
                cardTitle={
                  getLanguageFile(state.language).Career.partTimeJobDescription
                }
                cardDescription={
                  getLanguageFile(state.language).Career.partTimeDescription
                }
                cardButtonName={
                  getLanguageFile(state.language).Career.detailsButton
                }
              />
            </Row>
          </>
        ) : (
          <Slider style={{ width: "100%" }} {...sliderSettings}>
            <JobCard
              extraStyle={{
                width: 280,
              }}
              className="job-card"
              cardName="gameDeveloper_001"
              cardTitle={getLanguageFile(state.language).Career.gameApplication}
              cardDescription={
                getLanguageFile(state.language).Career
                  .gameApplicationDescription
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
            <JobCard
              className="job-card"
              cardName="electricalandElectronicsEngineer"
              cardTitle={getLanguageFile(state.language).Career.eeApplication}
              cardDescription={
                getLanguageFile(state.language).Career.eeApplicationDescription
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
            <JobCard
              className="job-card"
              cardName="softwareEngineer_006"
              cardTitle={getLanguageFile(state.language).Career.bdiApplication}
              cardDescription={
                getLanguageFile(state.language).Career.bdiApplicationDescription
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
            <JobCard
              className="job-card"
              cardName="sysadmin_001"
              cardTitle={getLanguageFile(state.language).Career.saApplication}
              cardDescription={
                getLanguageFile(state.language).Career.saApplicationDescription
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
            <JobCard
              className="job-card"
              cardName="softwareEngineer_005"
              cardTitle={getLanguageFile(state.language).Career.hmiApplication2}
              cardDescription={
                getLanguageFile(state.language).Career
                  .hmiApplicationDescription2
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
            <JobCard
              className="job-card"
              cardName="softwareEngineer_004"
              cardTitle={getLanguageFile(state.language).Career.nlpApplication}
              cardDescription={
                getLanguageFile(state.language).Career.nlpApplicationDescription
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
            <JobCard
              className="job-card"
              cardName="softwareEngineer_003"
              cardTitle={getLanguageFile(state.language).Career.hmiApplication}
              cardDescription={
                getLanguageFile(state.language).Career.hmiApplicationDescription
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
            <JobCard
              className="job-card"
              cardName="softwareEngineer_002"
              cardTitle={getLanguageFile(state.language).Career.msApplication}
              cardDescription={
                getLanguageFile(state.language).Career.msApplicationDescription
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
            <JobCard
              className="job-card"
              cardName="softwareEngineer_001"
              cardTitle={
                getLanguageFile(state.language).Career.softwareEngineer
              }
              cardDescription={
                getLanguageFile(state.language).Career
                  .softwareEngineerDescription
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
            <JobCard
              className="job-card"
              cardName="intern"
              cardTitle={
                getLanguageFile(state.language).Career.internJobDescription
              }
              cardDescription={
                getLanguageFile(state.language).Career.internDescription
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
            <JobCard
              className="job-card"
              cardName="partTime"
              cardTitle={
                getLanguageFile(state.language).Career.partTimeJobDescription
              }
              cardDescription={
                getLanguageFile(state.language).Career.partTimeDescription
              }
              cardButtonName={
                getLanguageFile(state.language).Career.detailsButton
              }
            />
          </Slider>
        )}

        {isDesktopOrLaptop && (
          <div className="view-all-container">
            <Button className="btn-primary-career" onClick={onViewAll}>
              {isViewAll
                ? getLanguageFile(state.language).Career.seeLess
                : getLanguageFile(state.language).Career.viewAll}
            </Button>
          </div>
        )}
      </Container>
      <Footer />
    </Container>
  )
}

export default CareerPage
